module.exports = {
    head: {
        a: 'Home page',
        b: 'Game center',
        c: 'Popularization',
        d: 'language',
    },
    tedian: 'Brand features: HELENS Electronic Entertainment has a variety of rich and interesting theme games, and focuses on the development and innovation of game games, so that players can relive the classics and have a full surprise, freshness and audio-visual experience. It is the first choice brand for gamers to enjoy leisure and entertainment. Based on the cutting-edge technology of enterprises, many games with rich and diverse contents have been developed, giving players the best game experience.',
    iconl: {
        a: '',
        b: '',
        c: '',
        d: '',
    },
    down: {
        ios: 'IOS Download',
        an: 'Android Download',
    },
    privacy:'Privacy Policy'
}